<template>
  <Layout>
    <PageHeader
      :title="newClient.isInvestor ? 'New Investor' : 'New Company'"
    />
    <div class="row">
      <div class="col-12 col-sm-7">
        <div class="card">
          <div class="row">
            <div class="col-12 col-sm-3">
              <div class="card-body">
                <div class="met-profile">
                  <div class="met-profile-main">
                    <div class="met-profile-main-pic">
                      <file-upload
                        ref="upload"
                        class="file profile-img"
                        v-model="image"
                        accept="image/png, image/gif, image/jpeg"
                        extensions="gif,jpg,jpeg,png"
                      >
                        <img
                          :src="
                            newClient.profileImg
                              ? $apiDomain + newClient.profileImg
                              : require('@/assets/images/no-image.jpg')
                          "
                          alt="Profil Fotoğrafı"
                          class="rounded-circle c-size-prof"
                        />
                        <span class="fro-profile_main-pic-change">
                          <i class="fas fa-camera"></i>
                        </span>
                      </file-upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-9">
              <div class="card-body">
                <div class="form-group mt-2">
                  <label
                    >{{ newClient.isInvestor ? "Fon" : "Company" }} Name</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="
                      newClient.isInvestor ? 'Fon Name' : 'Kurum Name'
                    "
                    autocomplete="nope"
                    v-model="newClient.name"
                    @blur="$v.newClient.name.$touch()"
                    :class="{
                      'is-invalid': $v.newClient.name.$error,
                    }"
                  />
                  <div class="invalid-feedback">Required</div>
                </div>
                <div class="form-group mt-2">
                  <label>Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    v-model="newClient.owner.name"
                    autocomplete="nope"
                    @blur="$v.newClient.owner.name.$touch()"
                    :class="{
                      'is-invalid': $v.newClient.owner.name.$error,
                    }"
                  />
                  <div class="invalid-feedback">Required</div>
                </div>

                <div class="form-group mt-2">
                  <label> E-Mail</label>
                  <input
                    type="mail"
                    class="form-control"
                    placeholder="E-Mail"
                    autocomplete="nope"
                    v-model="newClient.owner.email"
                    @blur="$v.newClient.owner.email.$touch()"
                    :class="{
                      'is-invalid': $v.newClient.owner.email.$error,
                    }"
                  />
                  <div class="invalid-feedback">Invalid e-mail</div>
                </div>

                <div class="form-group mt-2" v-if="!newClient.isInvestor">
                  <label> Password</label>
                  <div class="input-group">
                    <input
                      :type="passInputType"
                      class="form-control"
                      placeholder="Password"
                      v-model="newClient.owner.password"
                      @blur="$v.newClient.owner.password.$touch()"
                      :class="{
                        'is-invalid': $v.newClient.owner.password.$error,
                      }"
                    />

                    <span class="input-group-prepend">
                      <button
                        type="button"
                        class="btn btn-gradient-primary"
                        @click="passwordToggle"
                      >
                        <i class="fas fa-eye"></i>
                      </button>
                    </span>
                    <div class="invalid-feedback">Required</div>
                  </div>
                </div>
                <div class="form-group mt-2">
                  <label>Phone </label>

                  <the-mask
                    mask="0 (###) ### ## ##"
                    v-model="newClient.owner.tel"
                    class="form-control"
                    type="phone"
                    placeholder="Phone"
                  ></the-mask>
                </div>

                <!-- <div class="form-group mt-2" v-if="newClient.isInvestor">
                  <label
                    >{{ newClient.isInvestor ? "Fon" : "Kurum" }} Phone
                  </label>

                  <the-mask
                    mask="0 (###) ### ## ##"
                    v-model="newClient.tel"
                    class="form-control"
                    type="phone"
                    placeholder="Kurum Phone"
                  ></the-mask>
                </div> -->
                <!-- <div class="row" v-if="newClient.isInvestor">
                  <div class="col-6">
                    <label>Vergi Dairesi</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Vergi Dairesi"
                      autocomplete="nope"
                      v-model="newClient.taxOffice"
                    />
                  </div>
                  <div class="col-6">
                    <label>Vergi Numarası</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Vergi Numarası"
                      autocomplete="nope"
                      v-model="newClient.taxNo"
                    />
                  </div>
                </div> -->

                <div class="form-group mt-2">
                  <label>Adress</label>
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder="Adress"
                    autocomplete="nope"
                    v-model="newClient.address"
                  ></textarea>
                </div>

                <div class="float-right">
                  <router-link
                    :to="{
                      name:
                        $route.name == 'new-investor'
                          ? 'investors'
                          : 'companies',
                    }"
                    class="
                      btn btn-md btn-danger
                      waves-effect waves-light
                      pr-4
                      pl-4
                      mr-3
                    "
                  >
                    Cancel
                  </router-link>
                  <button
                    type="button"
                    class="
                      btn btn-md btn-primary
                      waves-effect waves-light
                      pr-4
                      pl-4
                    "
                    @click="addClient"
                  >
                    Add
                  </button>
                </div>
                <div class="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { HTTP } from "@/main-source";
import router from "@/router";
import {
  required,
  minLength,
  email,
  requiredIf,
} from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
  name: "NewInvestor",
  data() {
    return {
      passInputType: "password",
      cities: [],
      image: [],
      selectedCity: null,
      newClient: {
        name: null,
        isInvestor: this.$route.name == "new-investor" ? true : false,
        cityCode: null,
        address: null,
        tel: null,
        profileImg: null,
        owner: {
          name: null,
          email: null,
          password:
            this.$route.name == "new-investor" ? this.makeRandom(10) : null,
          tel: null,
        },
      },
    };
  },
  validations: {
    newClient: {
      isInvestor: { required },
      name: {
        required: requiredIf(function (newClientOb) {
          return newClientOb.isInvestor == true;
        }),
      },
      owner: {
        name: { required },
        email: { required, email },
        password: { required, minLength: minLength(5) },
      },
    },
  },
  components: {
    Layout,
    PageHeader,
  },
  created() {},
  methods: {
    passwordToggle: function () {
      this.passInputType == "password"
        ? (this.passInputType = "text")
        : (this.passInputType = "password");
    },
    makeRandom(length) {
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    addClient: function () {
      this.$v.newClient.$touch();
      if (!this.$v.newClient.$error) {
        this.newClient.isInvestor =
          this.newClient.isInvestor == true ? true : false;
        if (this.selectedCity) {
          this.newClient.cityCode = this.selectedCity.id;
        }
        if (this.newClient.owner.tel) {
          this.newClient.owner.tel = "0" + this.newClient.owner.tel;
        }
        if (this.newClient.tel) {
          this.newClient.tel = "0" + this.newClient.tel;
        }

        HTTP.post("firms", this.newClient)
          .then(() => {
            router.push({
              name:
                this.$route.name == "new-investor" ? "investors" : "companies",
            });
          })
          .catch((e) => {
            this.newClient.isInvestor = this.newClient.isInvestor
              ? true
              : "false";
            if (e.response.status == 409) {
              Swal.fire({
                icon: "warning",
                text: "E-mail used before",
              });
            }
            if (e.response.status == 412) {
              Swal.fire({
                icon: "warning",
                text: "Please fix the form errors",
              });
            }
          });
      }
    },
  },
  watch: {
    image: function () {
      let fd = new FormData();

      fd.append("files", this.image[0].file, this.image[0].name);

      HTTP.post("/files/", fd, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
        .then((res) => {
          this.newClient.profileImg = res.data[0].path;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
