var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.newClient.isInvestor ? 'New Investor' : 'New Company'}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-7"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-3"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"met-profile"},[_c('div',{staticClass:"met-profile-main"},[_c('div',{staticClass:"met-profile-main-pic"},[_c('file-upload',{ref:"upload",staticClass:"file profile-img",attrs:{"accept":"image/png, image/gif, image/jpeg","extensions":"gif,jpg,jpeg,png"},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}},[_c('img',{staticClass:"rounded-circle c-size-prof",attrs:{"src":_vm.newClient.profileImg
                            ? _vm.$apiDomain + _vm.newClient.profileImg
                            : require('@/assets/images/no-image.jpg'),"alt":"Profil Fotoğrafı"}}),_c('span',{staticClass:"fro-profile_main-pic-change"},[_c('i',{staticClass:"fas fa-camera"})])])],1)])])])]),_c('div',{staticClass:"col-12 col-sm-9"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-group mt-2"},[_c('label',[_vm._v(_vm._s(_vm.newClient.isInvestor ? "Fon" : "Company")+" Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newClient.name),expression:"newClient.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.newClient.name.$error,
                  },attrs:{"type":"text","placeholder":_vm.newClient.isInvestor ? 'Fon Name' : 'Kurum Name',"autocomplete":"nope"},domProps:{"value":(_vm.newClient.name)},on:{"blur":function($event){return _vm.$v.newClient.name.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newClient, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Required")])]),_c('div',{staticClass:"form-group mt-2"},[_c('label',[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newClient.owner.name),expression:"newClient.owner.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.newClient.owner.name.$error,
                  },attrs:{"type":"text","placeholder":"Name","autocomplete":"nope"},domProps:{"value":(_vm.newClient.owner.name)},on:{"blur":function($event){return _vm.$v.newClient.owner.name.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newClient.owner, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Required")])]),_c('div',{staticClass:"form-group mt-2"},[_c('label',[_vm._v(" E-Mail")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newClient.owner.email),expression:"newClient.owner.email"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.$v.newClient.owner.email.$error,
                  },attrs:{"type":"mail","placeholder":"E-Mail","autocomplete":"nope"},domProps:{"value":(_vm.newClient.owner.email)},on:{"blur":function($event){return _vm.$v.newClient.owner.email.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newClient.owner, "email", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Invalid e-mail")])]),(!_vm.newClient.isInvestor)?_c('div',{staticClass:"form-group mt-2"},[_c('label',[_vm._v(" Password")]),_c('div',{staticClass:"input-group"},[((_vm.passInputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newClient.owner.password),expression:"newClient.owner.password"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.$v.newClient.owner.password.$error,
                    },attrs:{"placeholder":"Password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.newClient.owner.password)?_vm._i(_vm.newClient.owner.password,null)>-1:(_vm.newClient.owner.password)},on:{"blur":function($event){return _vm.$v.newClient.owner.password.$touch()},"change":function($event){var $$a=_vm.newClient.owner.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.newClient.owner, "password", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.newClient.owner, "password", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.newClient.owner, "password", $$c)}}}}):((_vm.passInputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newClient.owner.password),expression:"newClient.owner.password"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.$v.newClient.owner.password.$error,
                    },attrs:{"placeholder":"Password","type":"radio"},domProps:{"checked":_vm._q(_vm.newClient.owner.password,null)},on:{"blur":function($event){return _vm.$v.newClient.owner.password.$touch()},"change":function($event){return _vm.$set(_vm.newClient.owner, "password", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newClient.owner.password),expression:"newClient.owner.password"}],staticClass:"form-control",class:{
                      'is-invalid': _vm.$v.newClient.owner.password.$error,
                    },attrs:{"placeholder":"Password","type":_vm.passInputType},domProps:{"value":(_vm.newClient.owner.password)},on:{"blur":function($event){return _vm.$v.newClient.owner.password.$touch()},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newClient.owner, "password", $event.target.value)}}}),_c('span',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-gradient-primary",attrs:{"type":"button"},on:{"click":_vm.passwordToggle}},[_c('i',{staticClass:"fas fa-eye"})])]),_c('div',{staticClass:"invalid-feedback"},[_vm._v("Required")])])]):_vm._e(),_c('div',{staticClass:"form-group mt-2"},[_c('label',[_vm._v("Phone ")]),_c('the-mask',{staticClass:"form-control",attrs:{"mask":"0 (###) ### ## ##","type":"phone","placeholder":"Phone"},model:{value:(_vm.newClient.owner.tel),callback:function ($$v) {_vm.$set(_vm.newClient.owner, "tel", $$v)},expression:"newClient.owner.tel"}})],1),_c('div',{staticClass:"form-group mt-2"},[_c('label',[_vm._v("Adress")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newClient.address),expression:"newClient.address"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Adress","autocomplete":"nope"},domProps:{"value":(_vm.newClient.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.newClient, "address", $event.target.value)}}})]),_c('div',{staticClass:"float-right"},[_c('router-link',{staticClass:"\n                    btn btn-md btn-danger\n                    waves-effect waves-light\n                    pr-4\n                    pl-4\n                    mr-3\n                  ",attrs:{"to":{
                    name:
                      _vm.$route.name == 'new-investor'
                        ? 'investors'
                        : 'companies',
                  }}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"\n                    btn btn-md btn-primary\n                    waves-effect waves-light\n                    pr-4\n                    pl-4\n                  ",attrs:{"type":"button"},on:{"click":_vm.addClient}},[_vm._v(" Add ")])],1),_c('div',{staticClass:"clearfix"})])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }